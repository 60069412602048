$primary: #326266;
$primary-hover: #326266;

$secondary: #0f0c45;
$secondary-hover: #080627;

$success: #00c07e;
$success-hover: #009a65;

$danger: #fc5661;
$danger-hover: #fb2432;

$warning: #f5a623;
$warning-hover: #e7940b;

$info: #0198e8;
$info-hover: #0177b5;

$light: #f8f9fa;
$light-hover: #e2e6ea;

$dark: #202020;
$dark-hover: #000;

.primary {
    color: $primary !important;
}

.secondary {
    color: $secondary !important;
}